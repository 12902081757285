<template>
    <div class="advent-calendar">

<div>
  <!--
  <category-block
      :target="category"
      position="position1"
      class="category-block category-block-2"
      :calendar="calendar"
    />
  -->
    <AdventCalendarBlock  :calendar="calendar" :availableCoupon="availableCoupon"/>

  </div>
      <v-col class="pt-6"
        ><v-btn
          class="more-button"
          color="white"
          x-large
          rounded
          block
          depressed
          :to="'/coupon/calendario-avvento-info'"
          >{{ $t("coupons.welcomeDiscounts.moreInfo") }}</v-btn
        ></v-col
      >
    </div>
</template>
<style lang="scss">
.advent-calendar{
  background-color: #106B3A;
}
.more-button{
  color: #e72c33 !important;
  font-weight: bold;
}
</style>
<script>
import categoryMixins from "~/mixins/category";
import matomoService from "@/service/analytics/matomoService";
import couponsService from "@/service/couponsService";
import AdventCalendarBlock from "@/components/coupon/AdventCalendarBlock.vue";
export default {
  components:{AdventCalendarBlock},
  mixins: [categoryMixins],
  data() {
    return {
      calendar: null,
      availableCoupon:null
    };
  },
  mounted(){
    matomoService.trackPageView("/adventCalendar");
    this.loadCalendar();
  },
  methods:{
    async loadCalendar(){
      this.calendar = (await couponsService.getAdventCalendar()).values;
      if(this.$route.params.action == 'activate'){
        let temp = this.calendar.find(item => item.status === "Available");
        temp.barCode = "";
        this.availableCoupon = temp;
      }
    },
  }
};
</script>
