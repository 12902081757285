import { render, staticRenderFns } from "./AdventCalendar.vue?vue&type=template&id=2420beb5"
import script from "./AdventCalendar.vue?vue&type=script&lang=js"
export * from "./AdventCalendar.vue?vue&type=script&lang=js"
import style0 from "./AdventCalendar.vue?vue&type=style&index=0&id=2420beb5&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Progetti\\abbondanza\\ebsn-front-vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2420beb5')) {
      api.createRecord('2420beb5', component.options)
    } else {
      api.reload('2420beb5', component.options)
    }
    module.hot.accept("./AdventCalendar.vue?vue&type=template&id=2420beb5", function () {
      api.rerender('2420beb5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/coupon/AdventCalendar.vue"
export default component.exports